import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	ViewChild
} from "@angular/core";
import {FeedEventsService} from "../../../commons/services/feed-events/feed-events.service";
import {Product} from "../../../shared/model/data/shop/product/Product";
import {Observable} from "rxjs";
import {NgxMasonryComponent, NgxMasonryOptions} from "ngx-masonry";
import {ResizedDirective} from "../../../shared/util/element-resized/directives/resized.directive";

@Component({
	selector: "app-product-feed",
	templateUrl: "./product-feed.component.html",
	styleUrls: ["./product-feed.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductFeedComponent implements OnInit, AfterViewInit, OnDestroy {

	// masonry options
	private _masonryOptions: NgxMasonryOptions = {
		// percentPosition: true,
		itemSelector: ".item",
		// resize: true,
		// transitionDuration: 0
	};

	@ViewChild("productFeedContainer", { static: true })
	private containerElement: NgxMasonryComponent;

	@Input()
	public feedData: Observable<Array<Product>>;

	private _feedElements: Array<Product> = [];

	// container size change subscription
	private containerSizeChangeEventSubscription: any;

	// timer for adjusting layout. Spawned after image is loaded.
	private layoutAdjustTimer: number;

	constructor(private productFeedEventsService: FeedEventsService,
	            private changeDetector: ChangeDetectorRef) {
	}

	/**
	 *
	 */
	ngOnInit() {

		// subscribe on size change events
		// this.containerSizeChangeEventSubscription = this.productFeedEventsService.containerSizeChangeEvent.subscribe(
		// 	() => {
		// 		this.adjustLayout();
		// 		setTimeout(() => {
		// 			this.adjustLayout();
		// 		}, 300);
			// }
		// );

		// fetch feed data and adjust layout
		this.feedData.subscribe((response) => {
			this._feedElements = response;
			this.changeDetector.detectChanges();

			setTimeout(() => {
				if (response && response.length) {
					this.changeDetector.detectChanges();
					this.adjustLayout();
				}
			}, 500);

		});

	}

	public ngAfterViewInit(): void {
		ResizeObserver.length
	}

	public onImageLoaded() {
		this.adjustLayoutDelayed();
	}

	ngOnDestroy(): void {
		// unsubscribe from feed events
		// if ()
		// this.containerSizeChangeEventSubscription.unsubscribe();
	}

	public adjustLayout(): void {
		// console.log("adjustLayout");
		this.containerElement.reloadItems();
		this.containerElement.layout();
	}

	public adjustLayoutDelayed(): void {
		// do not respawn if timer is present
		if (this.layoutAdjustTimer) {
			return;
		}
		// adjust layout in 300ms
		// @ts-ignore
		this.layoutAdjustTimer = setTimeout(() => {
			this.adjustLayout();
			this.layoutAdjustTimer = undefined;
		}, 300);
	}

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		// this.changeDetector.detectChanges();
	}

	get feedElements(): Array<Product> {
		return this._feedElements;
	}

	get options(): NgxMasonryOptions {
		return this._masonryOptions;
	}


}
