<div class="modal-body">

	<div class="review my-1" *ngFor="let rating of reviews.docs">

		<!--edit button-->
		<!--TODO RR-->
		<!--<button class="btn btn-sm btn-outline-primary float-right"-->
				<!--*ngIf="isEditable(rating.user._id)"-->
				<!--(click)="openEditModal()">-->
			<!--<i class="fi fi-ss-pencil"></i>-->
		<!--</button>-->

		<!--user avatar-->
		<app-user-avatar [userObject]="rating.user" [displayType]="avatarDisplayType"></app-user-avatar>
		<!--rating in stars-->
		<div>
			<ngb-rating id="rating" [rate]="rating.rating" [readonly]="true">
				<ng-template let-fill="fill">
					<span class="star" [class.filled]="fill === 100">&#9733;</span>
				</ng-template>
			</ngb-rating>
		</div>

		<!--review text-->
		<p>{{rating.message}}</p>

	</div>

	<div *ngIf="!reviews.totalDocs">
		{{'shop.ratings-modal.no-rating' | translate}}
	</div>


</div>
