import {ChangeDetectorRef, Component, Input, OnInit} from "@angular/core";
import {Product} from "../../../shared/model/data/shop/product/Product";
import {ApiCommunicationService} from "../../../shared/model/services/api-communication/api-communication.service";
import {EditCollectionRequest} from "../../../shared/model/request/collection/EditCollectionRequest";

export enum TextPositionAttribute {
	Underneath,
	Besides
}


@Component({
	selector: "app-product-display-small",
	templateUrl: "./product-display-small.component.html",
	styleUrls: ["./product-display-small.component.scss"]
})
export class ProductDisplaySmallComponent implements OnInit {

	@Input()
	public textPosition: TextPositionAttribute = TextPositionAttribute.Underneath;

	@Input()
	public imageSize = 110;

	@Input()
	public displayBuyNow = false;

	@Input()
	public displayRemoveButton = false;

	@Input()
	public product: Product;

	@Input()
	public collection: any;

	constructor(private api: ApiCommunicationService,
				private changeDetector: ChangeDetectorRef) {
	}

	ngOnInit() {
		if (this.product.variants && this.product.variants.length && this.product.variants[0].media && this.product.variants[0].media.length) {
			this.product.variants[0].media = this.product.variants[0].media.sort((a, b) => {
				if (a.position < b.position) { return -1; }
				if (a.position > b.position) { return 1; }
				return 0;
			});
		}
	}

	getTextPositionClass(): string {
		switch (this.textPosition) {
			case TextPositionAttribute.Besides:
				return "text-besides-image";
			case TextPositionAttribute.Underneath:
				return "text-under-image";
			default:
				return "--unknown";
		}
	}

	public removeFromCollectionAction(event: MouseEvent) {
		event.preventDefault();
		event.stopPropagation();

		const request = new EditCollectionRequest();
		request.products = [this.product._id];

		this.api.collection().removeFromCollection(this.collection._id, request).subscribe(() => {
				delete this.product;
				this.changeDetector.detectChanges();
		});
	}


}
