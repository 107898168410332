import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core";
import {Product} from "../../../shared/model/data/shop/product/Product";
import {ProductRatingsService} from "../../services/product-ratings.service";
import {ReviewListResponse} from "../../../shared/model/response/ReviewListResponse";
import Decimal from "decimal.js";
import {BehaviorSubject, Subscription} from "rxjs";
import {SearchRequest} from "../../../shared/model/request/search/SearchRequest";

@Component({
	selector: "app-product-rating",
	templateUrl: "./product-rating.component.html",
	styleUrls: ["./product-rating.component.scss"]
})
export class ProductRatingComponent implements OnInit, OnDestroy, AfterViewInit {

	@Input()
	public product: Product;

	@Input()
	public isFilterMode: false;

	@Input("currentFilter")
	public currentFilter: BehaviorSubject<SearchRequest> = new BehaviorSubject<SearchRequest>(undefined);

	@Output("onChange")
	public onChange = new EventEmitter<number>();

	@Input("reviews")
	public reviews: ReviewListResponse;

	@ViewChild("ratingRef", { static: false })
	public ratingRef = ElementRef;

	public defaultRating = 0;

	private filterSubscription: Subscription;

	constructor(private ratingsService: ProductRatingsService) {
	}

	public ngOnInit() {
	}

	public ngAfterViewInit(): void {
		if (this.isFilterMode) {
			this.filterSubscription = this.currentFilter.subscribe(f => {
				this.defaultRating = (f && f.rating) ? f.rating : 0;
				if (this.ratingRef) {
					// @ts-ignore
					this.ratingRef.update(this.defaultRating);
				}
			});
		}
	}

	public ngOnDestroy(): void {
		if (this.isFilterMode) {
			this.filterSubscription.unsubscribe();
		}
	}

	public setRating(rating: number) {
		if (this.isFilterMode) {
			if (rating) {
				this.onChange.emit(rating);
			}
		} else {
			this.ratingsService.openProductRateSubmitModal(this.reviews, rating, this.product);
		}
	}

	public getRating (): Decimal | number {
		if (this.product && this.product.rating !== null) {
			return this.product.rating;
		} else {
			return this.defaultRating;
		}
	}

}
