<a [routerLink]="['/product', product._id]"
	class="product-display-small my-1" [ngClass]="getTextPositionClass()"
	*ngIf="product">

	<img class="my-1" [src]="product.variants[0].media.length ? product.variants[0].media[0].urlSmall : '../../../assets/images/image-placeholder.png'" [style.width]="imageSize + 'px'" [style.height]="imageSize + 'px'">
	<p><span>{{product.title}}</span>
		<a *ngIf="displayBuyNow" class="btn btn-primary text-uppercase item-buy-now">{{"shop.buy-now" | translate}}</a>
	</p>

	<button class="btn btn-sm" *ngIf="displayRemoveButton"
			(click)="removeFromCollectionAction($event)"><i class="fi fi-ss-trash"></i></button>
</a>

