<ngx-masonry #productFeedContainer
				 [options]="options"
				 [updateLayout]="true"
			     (resized)="adjustLayoutDelayed()"
				 id="product-feed">

	<!--error message-->
	<div *ngIf="feedElements === null" class="text-center m-5">
		<i class="fi fi-rr-exclamation" aria-hidden="true"></i> {{ "commons.errors.general" | translate }}
	</div>

	<!--empty message-->
	<div class="empty-text text-center m-5" *ngIf="feedElements && !feedElements.length">
		<h1><i class="fi fi-sr-mug-hot-alt"></i></h1>
		<p>{{ "social.feed.empty-text" | translate }}</p>
	</div>

	<!--feed items-->
	<ng-container *ngIf="feedElements && feedElements.length">

		<!--feed items-->
		<app-product-feed-item *ngFor="let product of feedElements"
							   [product]="product"
							   [showPublisherLink]="false"
							   (imageLoaded)="onImageLoaded()"></app-product-feed-item>

	</ng-container>

</ngx-masonry>
