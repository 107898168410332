import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit} from "@angular/core";
import {ApiCommunicationService} from "../../../shared/model/services/api-communication/api-communication.service";
import {AuthorizationError} from "../../../shared/model/exception/AuthorizationError";
import {ModalServiceProxy} from "../../../commons/services/modal/modal.service.proxy";
import {Product} from "../../../shared/model/data/shop/product/Product";
import {Comment} from "../../../shared/model/data/social/Comment";
import {CommentType} from "../../../shared/model/data/enums/CommentType";
import {UserSessionService} from "../../../auth/session/services/user-session/user-session.service";

@Component({
	selector: "app-product-comments",
	templateUrl: "./product-comments.component.html",
	styleUrls: ["./product-comments.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCommentsComponent implements OnInit {

	@Input("item")
	public item: Product;

	constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService,
				@Inject(ModalServiceProxy) private modalServiceProxy: ModalServiceProxy,
				@Inject(ChangeDetectorRef) private changeDetector: ChangeDetectorRef,
				@Inject(UserSessionService) private sessionService: UserSessionService) {
	}

	ngOnInit() {
	}

	public sendComment(event: KeyboardEvent): void {

		// the source element of the event
		const inputElement: HTMLInputElement = (<HTMLInputElement>event.srcElement);

		// request to send
		const requestData = new Comment();
		requestData.message = inputElement.value;
		requestData.type = CommentType.PRODUCT;
		requestData.content = this.item._id;

		try {
			this.api.comment().comment(requestData)
				.subscribe((res) => {
					// add comment from server to the comments
					// @ts-ignore
					requestData.user = this.sessionService.userDataValue;
					requestData._id = res._id;
					this.item.comments.push(requestData);

					// reset field
					inputElement.value = "";

					// let the change detector know that changes happened
					this.changeDetector.markForCheck();
				});

		} catch (e) {
			// check if user is logged in, show login modal if not
			if (e instanceof AuthorizationError) {
				this.modalServiceProxy.get().showLoginModal();
			}
		}
	}

}
