import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";

import {SocialModule} from "../social/social.module";

import {TranslateModule} from "@ngx-translate/core";
import {ProductFeedComponent} from "./components/product-feed/product-feed.component";
import {ProductFeedItemComponent} from "./components/product-feed-item/product-feed-item.component";
import {ProductDisplayIconComponent} from "./components/product-display-icon/product-display-icon.component";
import {ProductRatingComponent} from "./components/product-rating/product-rating.component";
import {ProductDisplaySmallComponent} from "./components/product-display-small/product-display-small.component";
import {ProductImageGalleryComponent} from "./components/product-image-gallery/product-image-gallery.component";
import {ProductCommentsComponent} from "./components/product-comments/product-comments.component";
import {FormsModule} from "@angular/forms";
import {KeysPipe} from "./pipes/KeysPipe";
import {
	SubmitProductRatingModalComponent
} from "./modals/submit-product-rating-modal/submit-product-rating-modal.component";
import {ProductRatingsModalComponent} from "./modals/product-ratings-modal/product-ratings-modal.component";
import {ProductRatingsService} from "./services/product-ratings.service";
import {ProductFeedItemDirective} from "./directives/product-feed-item.directive";
import {ProductPageComponent} from "./modals/product-page/product-page.component";
import {ProductPageDataService} from "./services/product-page-data.service";
import {NgxMasonryModule} from "ngx-masonry";
import {CommonsModule} from "../commons/commons.module";
import {ElementResizedModule} from "../shared/util/element-resized/element-resized.module";
import {RouterModule} from "@angular/router";
import {LoginModule} from "../shared/login/login.module";

@NgModule({
	imports: [
		CommonModule,
		TranslateModule.forChild(),
		NgbModule,
		FormsModule,
		SocialModule,
		CommonsModule,
		ElementResizedModule,
		NgxMasonryModule,
		RouterModule,
		LoginModule
	],
	declarations: [
		ProductFeedComponent,
		ProductFeedItemComponent,
		ProductDisplayIconComponent,
		ProductRatingComponent,
		ProductDisplaySmallComponent,
		ProductImageGalleryComponent,
		ProductCommentsComponent,
		KeysPipe,
		SubmitProductRatingModalComponent,
		ProductRatingsModalComponent,
		ProductFeedItemDirective,
		ProductPageComponent
	],
	exports: [
		ProductFeedComponent,
		ProductFeedItemComponent,
		ProductDisplayIconComponent,
		ProductDisplaySmallComponent,
		ProductRatingComponent,
		ProductPageComponent
	],
	providers: [
		ProductRatingsService,
		ProductPageDataService
	]
})
export class ShopModule {
}
