<section [class.open]="product">
	<article id="product-details" *ngIf="product && product.variants && product.variants[0]">

		<!--product images-->
		<div id="product-details-images">

			<div id="meta-info">

				<!--like, share, add buttons-->
				<app-social-buttons [item]="product" [type]="'product'"></app-social-buttons>

				<!--user avatar-->
				<app-user-avatar [displayType]="_userAvatarDisplayType"
								 [userObject]="product.user"></app-user-avatar>

			</div>

			<!--<img class="cloudzoom" [src]="product.images.length ? product.images[0].imageUrl : '../../../assets/images/image-placeholder.png'">-->
			<app-product-image-gallery id="main-image"
									   *ngIf="product.variants[selectedVariant] && product.variants[selectedVariant].media.length"
									   [images]="product.variants[selectedVariant].media">
			</app-product-image-gallery>
			<img id="placeholder-image"
				 *ngIf="!product.variants[selectedVariant] || !product.variants[selectedVariant].media.length"
				 src="../../../assets/images/image-placeholder.png">

		</div>

		<!--product details panel-->
		<div id="product-details-main">

			<summary>
				<!--category of the product-->
				<div class="category-name"
					 *ngIf="product.category">
					<a [routerLink]="['/']" [queryParams]="{'search' : getCategorySearchQueryParams()}" queryParamsHandling="merge">{{product.category.name}}</a>
				</div>

				<!--name of the product-->
				<h1 class="item-title text-4xl">{{product.title}}</h1>

				<!--brand name-->
				<div *ngIf="product.brandName" class="brand-name">
					<a class="hover:text-pink-primary"
						[routerLink]="['/']"
						[queryParams]="{'search' : getBrandSearchQueryParams()}">by {{product.brandName}}</a>
				</div>

				<!--rating-->
				<div class="rating">

					<!--rating-->
					<app-product-rating [product]="product" [reviews]="reviews"></app-product-rating>

					<!--reviews-->
					<a href="javascript:void(0);" (click)="showRatingAction()">
						<!--<span i18n>-->
						<!--{reviews.totalDocs, plural,-->
						<!--=0 { {{"shop.reviews.none" | translate}} }-->
						<!--=1 { {{"shop.reviews.single" | translate}} }-->
						<!--other { {{"shop.reviews.multiple" | translate:{value: (reviews ? reviews.totalDocs : 0)} }} }-->
						<!--}-->
						<!--</span>-->
						<span>
					{{"shop.reviews.multiple" | translate:{value: (reviews ? reviews.totalDocs : 0)} }}
				</span>
					</a>

				</div>

				<!--price-->
				<h2 class="item-price my-2 text-4xl" *ngIf="product.variants[selectedVariant ? selectedVariant : 0]">
					{{product.variants[selectedVariant ? selectedVariant : 0].currency}}{{
					product.variants[selectedVariant ? selectedVariant : 0].price | number:'1.2-2'
					}}</h2>

				<!--			<p *ngIf="product.variants[selectedVariant ? selectedVariant : 0]">-->
				<!--				{{product.variants[selectedVariant ? selectedVariant : 0].}}-->
				<!--			</p>-->

				<!--item description-->
				<p class="item-description">
					<span *ngIf="product.description" class="item-description"
						  [innerHTML]="product.description">
					</span>

				</p>

				<span class="add-to-shop" *ngIf="showAddToCollectionLabel">

			<div>
				Add this product to your shop and recieve commisions on sales
			</div>

			<app-social-buttons hidden
								[showLikeButton]="false"
								[showReportContentButton]="false"
								[showShareButton]="false"
								[item]="product" type="product">
			</app-social-buttons>

		</span>

				<div class="display-stock" [ngClass]="{'out-of-stock' : !available}">
					<span *ngIf="available; else outOfStockLabel">In Stock.</span>
					<ng-template #outOfStockLabel>OUT OF STOCK</ng-template>
				</div>

				<!--main form (buy/select)-->
				<div id="item-shop-form">

					<!--seller recommendations-->
					<!--<div class="product-recommendations" *ngIf="product.recommendations && product.recommendations.length">-->

					<!--&lt;!&ndash;panel for hiding recommended products&ndash;&gt;-->
					<!--<ngb-accordion #acc="ngbAccordion" activeIds="product-recommendations-container">-->
					<!--<ngb-panel id="product-recommendations-container"-->
					<!--title="{{ 'shop.item-shop-form.seller-recommends' | translate:{seller:'Lust'} }}">-->
					<!--<ng-template ngbPanelContent>-->

					<!--<div style="display: flex; align-items: center;"-->
					<!--*ngFor="let recommendedProduct of product.recommendations">-->
					<!--<input type="checkbox">-->
					<!--<app-product-display-small [textPosition]="_recommendedItemsViewType"-->
					<!--[imageSize]="40" [product]="recommendedProduct">-->
					<!--</app-product-display-small>-->
					<!--</div>-->

					<!--</ng-template>-->
					<!--</ngb-panel>-->
					<!--</ngb-accordion>-->

					<!--</div>-->

					<div class="variant-selector">

						<div *ngFor="let variant of product.variants; let i = index"
							 class="variant-select"
							 [ngClass]="{'selected': (selectedVariant ? selectedVariant : 0) == i}"
							 (click)="selectVariant(i)">

							<p class="variant-name">{{variant.name}}</p>
							<p class="variant-price">{{variant.currency}} {{variant.price}}</p>

						</div>

					</div>

					<form (submit)="buyAction()" id="item-buy-form" class="my-4">

						<div class="actions-wrapper">

							<!--quantity picker-->
							<div class="quantity-picker-wrapper">
								<input type="number" name="quantity" required min="1" [(ngModel)]="quantity"
									   class="form-control quantity"
									   placeholder="{{ 'shop.item-shop-form.quantity-placeholder' | translate }}"
									   [disabled]="!isVariantAvailable">
							</div>

							<!--buy now-->
							<div class="buy-button-wrapper">
								<button type="submit"
										class="item-buy-now btn btn-primary"
										[disabled]="!isVariantAvailable"
								>{{isVariantAvailable ? ("shop.buy-now" | translate) : ("shop.out-of-stock" | translate) }}
								</button>
							</div>
						</div>

					</form>
				</div>

			</summary>

			<table class="specs">
				<tr class="spec" *ngFor="let entry of getSpecs(product.variants[selectedVariant]) | keys">
					<td class="spec-name">{{entry.key}}</td>
					<td>{{entry.value}}</td>
				</tr>
			</table>

			<button class="btn btn-outline-primary btn-report-product"
					(clickAuthorized)="reportProductAction()">
				Report this product...
			</button>

			<!--comments section-->
			<div id="item-comments">
				<p class="nui-label">{{ 'social.comments.title' | translate }}</p>

				<!--comments part-->
				<app-product-comments [item]="product"></app-product-comments>
			</div>

		</div>

		<!--more like this-->
		<aside id="more-like-this" class="p-4">

			<div id="more-like-this-header" class="text-center mb-3">
				<p>{{ "shop.more-like-this.title" | translate }}</p>
				<!--<a class="text-capitalize" href="javascript:void(0);">{{ "shop.more-like-this.show-results" | translate }}</a>-->
			</div>

			<div *ngIf="!moreLikeThis" class="d-flex flex-row justify-content-center align-items-center">
				<i class="fi fi-bs-refresh"></i>
			</div>

			<div id="more-like-this-content" *ngIf="moreLikeThis">
				<app-product-display-small *ngFor="let product of moreLikeThis"
										   [product]="product"></app-product-display-small>
			</div>
		</aside>

	</article>

	<!--back button-->
	<a id="page-back-button" (click)="back()" placement="right" container="body" ngbTooltip="Back">
		<svg xmlns="http://www.w3.org/2000/svg" id="Isolation_Mode" data-name="Isolation Mode" viewBox="0 0 24 24"
			 width="512" height="512">
			<path
				d="M16.041,24,6.534,14.48a3.507,3.507,0,0,1,0-4.948L16.052,0,18.17,2.121,8.652,11.652a.5.5,0,0,0,0,.707l9.506,9.52Z"/>
		</svg>
	</a>

</section>
