<div class="modal-body">

	<!--delete button-->
	<button class="btn btn-outline-primary float-right"
			*ngIf="review._id"
			[disabled]="isLoading"
			(click)="deleteReviewAction()">
		<i class="fi fi-ss-trash"></i>
	</button>

	<!--header-->
	<h3 *ngIf="!review._id">{{ 'Reviews for ' + productName}}</h3>
	<h3 *ngIf="review._id">{{ 'shop.submit-rating-modal.title-edit' | translate }}</h3>


	<!--edit form-->
	<form #reviewForm (submit)="submitReviewAction()">

		<!--rating-->
		<label for="rating">{{ 'shop.submit-rating-modal.rating' | translate }}</label>
		<div>
			<ngb-rating id="rating" [(rate)]="review.rating" [readonly]="isLoading">
				<ng-template let-fill="fill">
					<span class="star" [class.filled]="fill === 100">&#9733;</span>
				</ng-template>
			</ngb-rating>
		</div>
		<!--hidden rating input-->
		<input id="rating-hidden"
		       name="rating-hidden"
		       type="hidden"
		       class="form-control"
		       required
		       [(ngModel)]="review.rating"
		>
		<!--invalid feedback message-->
		<div class="invalid-feedback">
			{{ 'shop.submit-rating-modal.rating-invalid' | translate}}
		</div>

		<!--review-->
	<!--	<label for="review">{{ 'shop.submit-rating-modal.review' | translate }}</label>-->
		<textarea name="review"
				  id="review"
				  class="form-control mb-3"
				  type="text"
				  rows="3"
				  required
				  [disabled]="isLoading"
				  [(ngModel)]="review.message"
				  [placeholder]="'Write your review'">
			</textarea>
		<!--invalid feedback message-->
		<div class="invalid-feedback">
			{{ 'shop.submit-rating-modal.review-invalid' | translate}}
		</div>

		<!--buttons-->
		<div class="mt-3 d-flex flex-row justify-content-between">

			<!--cancel button-->
			<button class="btn btn-outline-dark text-uppercase"
					[disabled]="isLoading"
					(click)="closeAction.emit()"
			>{{ 'profile.edit-modal.cancel' | translate }}</button>

			<!--save button-->
			<button class="btn btn-primary text-uppercase"
					[disabled]="isLoading"
					type="submit">
				<i class="fi fi-bs-refresh" [hidden]="!isLoading"></i>
				{{ 'shop.submit-rating-modal.submit-button' | translate }}
			</button>
		</div>

	</form>



</div>
