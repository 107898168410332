import {Component, EventEmitter, inject, Input, OnInit, Output} from "@angular/core";
import {Product} from "../../../shared/model/data/shop/product/Product";
import {UserSessionService} from "../../../auth/session/services/user-session/user-session.service";
import {mainFeedAnimations} from "../../../commons/animations/mainFeedAnimations";
import {ActivatedRoute, Router} from "@angular/router";
import {ProductPageDataService} from "../../services/product-page-data.service";
import {Content} from "../../../shared/model/content/Content";

@Component({
	selector: 'app-product-feed-item',
	templateUrl: "./product-feed-item.component.html",
	styleUrls: ["./product-feed-item.component.scss"],
	animations: [mainFeedAnimations]
})
export class ProductFeedItemComponent implements OnInit {

	private route = inject(ActivatedRoute);

	@Input()
	public product: Product;

	@Input()
	public resize = false;

	@Input()
	showPublisherLink = true;

	private action: boolean;

	@Output()
	public imageLoaded: EventEmitter<void> = new EventEmitter<void>();

	scrollToComments: boolean = false;

	constructor(private productPageService: ProductPageDataService,
				public userSessionService: UserSessionService,
				public router: Router) {
	}

	ngOnInit(): void {

		// ordering the images of the variants
		if (this.product.variants && this.product.variants.length && this.product.variants[0].media && this.product.variants[0].media.length) {
			this.product.variants[0].media = this.product.variants[0].media.sort((a, b) => {
				if (a.position < b.position) { return -1; }
				if (a.position > b.position) { return 1; }
				return 0;
			});
		}
	}

	openProductPage(_id: string) {
		this.router.navigate(['product', _id], {relativeTo: this.route})
	}

	public getItemContainerSize() {
		if (this.resize && this.product.numberOfLikes > 35) {
			return true;
		} else if (this.resize && this.product.numberOfLikes >= 3 && this.product.numberOfLikes <= 35) {
			return true;
		}
	}

	public addToBasket() {

	}

	public preventNav() {
		this.userSessionService.preventNavigation();
	}

}
