import {Component, ElementRef, EventEmitter, OnInit, ViewChild} from "@angular/core";
import {ApiCommunicationService} from "../../../shared/model/services/api-communication/api-communication.service";
import {Review} from "../../../shared/model/review/Review";
import {SweetAlertService} from "../../../commons/services/sweetalert/sweet-alert.service";

@Component({
	selector: "app-submit-product-rating-modal",
	templateUrl: "./submit-product-rating-modal.component.html",
	styleUrls: ["./submit-product-rating-modal.component.scss"]
})
export class SubmitProductRatingModalComponent implements OnInit {

	public closeAction: EventEmitter<void> = new EventEmitter<void>();

	public productId: string;
	public productName: string;

	// rating and review model
	public review: Review = new Review();

	// loading indicator
	public isLoading = false;

	@ViewChild("reviewForm", { static: true })
	private _reviewForm: ElementRef;

	constructor(private api: ApiCommunicationService,
				private sweetAlertService: SweetAlertService) {
	}

	ngOnInit() {
	}

	public submitReviewAction() {

		// check form validity
		const isFormValid = (<HTMLFormElement> this._reviewForm.nativeElement).checkValidity();

		// set bootstrap class indicating state
		(<HTMLFormElement> this._reviewForm.nativeElement).classList.add("was-validated");

		if (isFormValid) {

			// set loading, disable all inputs
			this.isLoading = true;

			// rating id will tell whether this is an existing rating or not
			if (this.review._id) {
				// rating does exist, send update request
				this.api.review()
					.editReview(this.review._id, this.review)
					.subscribe((data) => {
						this.closeAction.emit();
					}, (e) => {
						// error handling
					}, () => {
						this.isLoading = false;
					});
			} else {
				// rating does not exist, send create request
				this.api.review().review(this.productId, this.review)
					.subscribe((data) => {
						this.closeAction.emit();
					}, (e) => {
						// error handling
					}, () => {
						this.isLoading = false;
					});
			}
		}
	}

	public deleteReviewAction() {

		this.sweetAlertService.confirm({title: "Are you sure to delete this review?"}).then((response => {

			if (response.value) {
				// set loading, disable all inputs
				this.isLoading = true;

				// send delete request
				this.api.review()
					.deleteReview(this.review._id)
					.subscribe((data) => {
						this.closeAction.emit();
					}, (e) => {
						// error handling
					}, () => {
						this.isLoading = false;
					});
			}
		}));

	}

}
