import {Injectable} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {ApiCommunicationService} from "../../shared/model/services/api-communication/api-communication.service";
import {map, switchMap} from "rxjs/operators";

// https://betterprogramming.pub/create-a-modal-for-your-angular-app-without-libs-671bd7280867
@Injectable()
export class ProductPageDataService {

	private productId$ = new ReplaySubject<string>(1);

	private product$ = this.productId$.pipe(
		switchMap(id => this.api.product().getProduct(id)),
		map(response => {
			response.variants.map(v => {
				if (v.media && v.media.length && v.media[0].position !== undefined) {
					v.media = v.media.sort((a, b) => {
						if (a.position < b.position) {
							return -1;
						}
						if (a.position > b.position) {
							return 1;
						}
						return 0;
					});
				}
			});

			return response;
		})
	);

	constructor(private api: ApiCommunicationService) {
	}

	getProduct$() {
		return this.product$;
	}

	setProductId(id: string) {
		this.productId$.next(id);
	}
}
