<figure class="item" *ngIf="product && product.variants && product.variants[0]"
        [ngClass]="{'bigger' : getItemContainerSize(), 'big' : getItemContainerSize()}" [@fadeIn]>

	<!--image container (image box)-->
	<div class="item-image-container" (click)="openProductPage(product._id)">

		<!--product image-->
		<img [src]="product.variants[0]?.media[0]?.urlLarge || product.variants[0]?.media[0]?.urlMedium || '../../../assets/images/image-placeholder.png'"
			 			[alt]="product.title">

		<!--alternative price (displayed on small screens)-->
		<a class="item-buy-now btn btn-primary text-uppercase" (click)="addToBasket()">

			<!--price (on mobile)-->
			<span class="item-price-alt">
				{{product.variants[0].currency}} {{ product.variants[0].price | number:'1.2-2' }}
			</span>

		</a>

		<!--social buttons-->
		<div class="social-buttons">
			<app-social-button-like (click)="preventNav()" id="prevent" [type]="'product'" [item]="product"></app-social-button-like>
			<app-social-button-comment (click)="scrollToComments = true" [item]="product" [title]="product.title" [type]="'product'"></app-social-button-comment>
		</div>

	</div>

	<!--captions (name, seller, price)-->
	<figcaption>

		<!--item name and seller-->
		<h5 class="item-title">

			<!--item name-->
			<a class="item-name" (click)="openProductPage(product._id)">{{product.title}}</a>

			<!--seller-->
			<a class="item-publisher"
			   *ngIf="showPublisherLink"
			   [routerLink]="['/user', product.user.userName, product.user._id]">
				<span class="highlight" [title]="product.user.userName">from {{product.user.displayName}}</span>
			</a>

		</h5>

		<!--price-->
		<!--<h5 class="item-price text-primary">{{product.variants[0].currency}}{{ product.variants[0].price | number:'1.2-2' }}</h5>-->

	</figcaption>

</figure>
